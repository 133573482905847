let SelfTest = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;

  /**
   * 获取所有答题人
   * @param {any} success
   * @param {any} error
   */
  this.MyselfAnswerInfo = function (userId, success, error) {
    var url = ServiceBaseAddress + `/api/AnswerUserInfo/Staff/${userId}/%20`;
    tokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  this.BatchStaff = function (userIds, success, error) {
    var map = {
      userIds: userIds,
      keyWord: '',
      pageIndex: 1,
    }
    var url = ServiceBaseAddress + `/api/AnswerUserInfo/BatchStaff`
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      map,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  };
  /**
   * 获取所有试题集
   * @param {any} success
   * @param {any} error
   */
  this.Questionnaire = function (success, error) {
    var url = ServiceBaseAddress + "/api/Questionnaire";
    tokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取用户测试记录
   * @param {*} id
   * @param {*} pageIndex
   * @param {*} success
   * @param {*} error
   */

  this.getUserResultByUser = function (id, keyWord, pageIndex, qId, customerPhone, startTime, endTime, success, error) {
    if (!keyWord) {
      keyWord = "%20";
    }
    qId = qId * 1;
    if (!customerPhone) {
      customerPhone = "%20";
    }
    if (!startTime) {
      startTime = "%20";
    }
    if (!endTime) {
      endTime = "%20";
    }

    var url = ServiceBaseAddress + `/api/Questionnaire/UserResultByUser/${id}/${keyWord}/${pageIndex}/%20/%20/${qId * 1}/${customerPhone}/${startTime}/${endTime}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 导出文件
   * @param roleArr
   * @param success
   * @param error
   */
  this.exportFile = function (fileType, ids, success, error) {
    var url = ServiceBaseAddress + `/api/Questionnaire/Export/${fileType}`;
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      ids,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 查看导出列表
   * @param {*} success
   * @param {*} error
   */
  this.ExportTasks = function (success, error) {
    var url = ServiceBaseAddress + "/api/ExportTask/Tasks";
    tokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取用户症状问诊记录
   * @param {*} id
   * @param {*} pageIndex
   * @param {*} success
   * @param {*} error
   */

  this.getUserSelfDiagnosisRecord = function (phone, userId, startTime, endTime, keyWord, pageIndex, success, error) {
    if (!phone) {
      phone = "%20"
    }
    if (!keyWord) {
      keyWord = "%20";
    }
    if (!startTime) {
      startTime = "%20";
    }
    if (!endTime) {
      endTime = "%20";
    }
    var url = ServiceBaseAddress + `/api/UserSelfDiagnosisRecord/SelfDiagnosis/${phone}/${userId}/${startTime}/${endTime}/${keyWord}/${pageIndex}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  /**
   * 获取用户疾病问诊记录
   * @param {*} id
   * @param {*} pageIndex
   * @param {*} success
   * @param {*} error
   */

  this.getUserSelfDiagnosisRecordTCMDisease = function (phone, userId, startTime, endTime, keyWord, pageIndex, success, error) {
    if (!phone) {
      phone = "%20"
    }
    if (!keyWord) {
      keyWord = "%20"
    }
    if (!startTime) {
      startTime = "%20"
    }
    if (!endTime) {
      endTime = "%20"
    }
    var url = ServiceBaseAddress + `/api/UserSelfDiagnosisRecord/TCMDisease/${phone}/${userId}/${startTime}/${endTime}/${keyWord}/${pageIndex}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  };
  // 问诊列表
  this.GetCustomerRecord = function (customerPhone,pageIndex,  success, error) {
    if (!customerPhone) {
      customerPhone = "%20";
    }
    var url = ServiceBaseAddress + `/api/UserSelfDiagnosisRecord/GetCustomerRecord/0/${customerPhone}/${pageIndex}`;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.OrgAnswerInfo = function (userIds, keyWord, pageIndex, success, error) {
    var map = {
      userIds: userIds,
      keyWord: keyWord,
      pageIndex: pageIndex,
    };
    var url = ServiceBaseAddress + `/api/AnswerUserInfo/Staff`;
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      map,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取测试记录
   * @param {*} pageIndex
   * @param {*} customerPhone
   * @param {*} success
   * @param {*} error
   */
  this.UserResultByUser = function (keyWord, pageIndex, qId,customerPhone,startTime,endTime, success, error) {
    if (!keyWord) {
      keyWord = "%20";
    }
    if (!startTime) {
      startTime = "%20";
    }
    if (!endTime) {
      endTime = "%20";
    }
    var url = ServiceBaseAddress + `/api/Questionnaire/UserResultByUser/0/${keyWord}/${pageIndex}/%20/%20/${qId}/${customerPhone}/${startTime}/${endTime}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
};

export { SelfTest };
